.container {
    width: 500px;
    height: 100vh;
    margin: 0 auto;

}

#user {
    background: rgba(0, 0, 0, 0.82);
}

.user {
    background: url("../././src/companents/img/photo_2023-04-25_17-49-22.jpg") no-repeat center/cover;
    height: 100vh;
    padding: 0 15px;

}

.name {

    font-family: 'Montserrat Alternates', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 35px;
    line-height: 43px;
    color: #FFFFFF;
}

.titleDev {
    font-family: 'Montserrat Alternates', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
    position: fixed;
    top: 65%;
}

.description {
    font-family: 'Montserrat Alternates', sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 18px;
    color: #FFFFFF;
    position: fixed;
    top: 70%;
}

.next {
    background: linear-gradient(180deg, rgba(255, 255, 255, 0.5) 0%, rgba(255, 255, 255, 0.175) 100%);
    width: 50px;
    height: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}
.buttonNext{
    position: absolute;
    bottom: 50px;
    right: 50px;
}
.blockTitle {
    width: 60vh;
    position: fixed;
    top: 50%;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

/*home*/
.home {
    height: 100vh;
    background: #181818;
}

.circle {
    margin: 20px 0 0 0;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 187px;
    height: 187px;
    border-radius: 50%;
    background: #181818;
    box-shadow: inset -24px -9px 21px rgba(72, 72, 72, 0.25), inset 2px 8px 21px #000000;
}

.circle2 {
    width: 140px;
    height: 140px;
    border-radius: 50%;
    background: #181818;
    box-shadow: -11px -9px 21px rgba(72, 72, 72, 0.25), 6px 7px 21px #000000;
    background: url(".././src/companents/img/photo_2023-02-10_18-38-18.jpg") no-repeat center/cover;

}

.circleImg {

}

.blockProfile {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 40%;
}

.HomeName {
    font-family: 'Raleway', sans-serif;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    color: #FFFFFF;
}


.bigBlock {
    display: flex;
    justify-content: space-around;
    color: white;
}


.projects {
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    padding: 10px;
    height: 50vh;
    background: #171717;
    box-shadow: inset -8px -9px 11px rgba(30, 30, 30, 0.25), inset 6px 7px 10px #000000;
    border-radius: 10px;
}

.tel {
    margin: 0 0 10px 0;
    width: 80px;
    height: 80px;
    background: #171717;
    box-shadow: inset -8px -9px 11px rgba(30, 30, 30, 0.25), inset 6px 7px 10px #000000;
    border-radius: 22px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.cont {
    overflow-y: scroll;
    width: 80px;
    height: 250px;
    background: #171717;
    box-shadow: inset -8px -9px 11px rgba(30, 30, 30, 0.25), inset 6px 7px 10px #000000;
    border-radius: 10px;

}

.contacts {
    margin: 0 20px 0 0;
    display: flex;
    flex-direction: column;
    align-items: center;
}


#scroll::-webkit-scrollbar {
    border-radius: 10px;
    width: 5px;
    height: 20px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

#scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    width: 5px;
    height: 20px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
    background-color: #808080;
}


.project {
    margin: 20px 0;
    padding: 0 10px;
    width: 80%;
    height: 66px;
    background: #0B0B0B;
    box-shadow: -11px -9px 21px rgba(72, 72, 72, 0.25), 6px 7px 21px #000000;
    border-radius: 6px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}


.contCircle {
    margin: 10px;
    width: 50px;
    height: 50px;
    border-radius: 20%;
    background: #181818;
    box-shadow: -11px -9px 21px rgba(72, 72, 72, 0.25), 6px 7px 21px #000000;
    display: flex;
    align-items: center;
    justify-content: center;
}
.icon{
    color: white;
 }
@media (max-width: 450px) {
    .container{
        width: 100%;
    }
    .blockTitle {
        width: 100%!important;

    }
    .projects {
        width: 93%;
    }
    .contacts {
        margin: 0 6px 0 0;

    }
    .tel {
        width: 70px;
        height: 66px;
    }
}